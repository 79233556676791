import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { LAYOUT_DEFAULTS } from '../../../utils/constants';
import { getPublicImageUrl } from '../../../utils/helpers';
import { EnumPublicPagesImage } from '../../../utils/enums';
import CustomButton from '../../../components/button/CustomButton';
import useAuth from '../../../utils/hooks/useAuth';

const NextLevel = () => {
  const navigator = useNavigate();
  const {
    authData: { accessToken },
  } = useAuth();

  const isUserLoggedIn = accessToken !== null;

  return (
    <Stack
      direction="row"
      spacing={{ md: 8 }}
      my={{ xs: 10, lg: 10 }}
      sx={{ ...LAYOUT_DEFAULTS }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack
        justifyContent="start"
        alignItems="start"
        spacing={{ xs: 3, lg: 6 }}
        sx={{ width: { xs: '100%', lg: '50%' } }}
      >
        <Stack spacing={6}>
          <Typography sx={{ typography: { xs: 'headline2', lg: 'headline1' }, lineHeight: '128%' }}>
            Ready to Take Your Business to the Next Level?
          </Typography>
          <Typography sx={{ typography: { xs: 'publicBody6Regular', lg: 'publicBody5Regular' } }}>
            Combine the power of Spona Media’s performance marketing with our robust E2E Tool or
            simply hire top talent and offer your services—all in one platform.”
          </Typography>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 4 }}>
            {!isUserLoggedIn && (
              <CustomButton
                variant="primary"
                sx={{
                  width: 'fit-content',
                  borderRadius: '6.25rem',
                  height: '2rem',
                  textTransform: 'none',
                }}
                endIcon={<ChevronRightIcon sx={{ fontSize: '1.75rem !important' }} />}
                onClick={() => navigator('./register')}
              >
                Sign Up Free
              </CustomButton>
            )}
            <CustomButton
              href={process.env.REACT_APP_CALENDLY_URL as string}
              variant="secondary"
              sx={{
                width: 'fit-content',
                borderRadius: '6.25rem',
                height: '2rem',
                textTransform: 'none',
              }}
              endIcon={<ChevronRightIcon sx={{ fontSize: '1.75rem !important' }} />}
              // onClick={() => navigator('./login')}
            >
              Talk to Our Team
            </CustomButton>
          </Stack>
        </Stack>

        <Box
          src={getPublicImageUrl(EnumPublicPagesImage.WindowReflection)}
          component="img"
          sx={{ width: '100%', display: { xs: 'block', md: 'none' } }}
          alt="Person analyzing data on a tablet"
        />
      </Stack>

      <Box
        src={getPublicImageUrl(EnumPublicPagesImage.WindowReflection)}
        component="img"
        sx={{ width: '37%', display: { xs: 'none', md: 'block' } }}
        alt="Person analyzing data on a tablet"
      />
    </Stack>
  );
};
export default NextLevel;
