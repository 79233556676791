import { useEffect, useState } from 'react';
import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { CompanyRole, EnumPriceLookup } from 'tdc-web-backend/enums/enums';
import { CompanySubscriptionInfo } from 'tdc-web-backend/companies/schemas';
import { SubscriptionPriceDto } from 'tdc-web-backend/subscription-prices/schemas';
import {
  CreateSubscriptionSubscribeDto,
  SubscriptionSubscribeDto,
} from 'tdc-web-backend/subscriptions/schemas';
import { BuyerPricingType, CheckoutButtonDefaultProps, IPlanDetails } from '../../interface';
import CustomButton, { Variants } from '../../../../../components/button/CustomButton';
import { LAYOUT_DEFAULTS } from '../../../../../utils/constants';
import LogoSection from '../../components/LogoSection';
import PricingFaq from '../../components/PricingFaq';
import GetDemoSection from '../../components/GetDemoSection/GetDemoSection';
import PricingCard from '../../components/PricingCard';
import PlanDetails from '../../components/PlanDetails/PlanDetails';
import useAuth from '../../../../../utils/hooks/useAuth';
import useGetOne from '../../../../../utils/hooks/crud-hooks/useGetOne';
import useCreate from '../../../../../utils/hooks/crud-hooks/useCreate';
import useGetMany from '../../../../../utils/hooks/crud-hooks/useGetMany';
import useUserCompany from '../../../../../utils/hooks/useUserCompany';

type IBuyerPricingData = {
  basic: IPlanDetails;
  standard: IPlanDetails;
  premium: IPlanDetails;
  selfService: IPlanDetails;
  sponaConnect: IPlanDetails;
};

export const buyerPricingData: IBuyerPricingData = {
  basic: {
    name: 'Basic',
    pricing: {
      monthlyPrice: '€99.99',
      annualPrice: '€84.99',
    },
    chckedItems: [
      { title: 'Management of up to 2 projects', sx: { fontFamily: 'Gellix-Bold' } },
      { title: 'Built-in chat & video calls' },
      { title: 'Company calendar' },
      { title: 'Notifications' },
      { title: 'Ready-to-use contracts' },
      { title: 'Milestone & task management' },
      { title: 'Payment processing' },
      { title: 'Expense tracking' },
      { title: '', hideCheck: true },
      { title: '', hideCheck: true },
    ],
  },
  standard: {
    name: 'Standard',
    pricing: {
      monthlyPrice: '€299.99',
      annualPrice: '€254.99',
    },
    chckedItems: [
      { title: 'Management of up to 8 projects', sx: { fontFamily: 'Gellix-Bold' } },
      { title: 'Built-in chat & video calls' },
      { title: 'Company calendar' },
      { title: 'Notifications' },
      { title: 'Ready-to-use contracts' },
      { title: 'Milestone & task management' },
      { title: 'Payment processing' },
      { title: 'Expense tracking' },
      { title: 'Priority support' },
      { title: 'Dedicated account manager' },
    ],
  },
  premium: {
    name: 'Premium',
    pricing: {
      monthlyPrice: '€799.99',
      annualPrice: '€679.99',
    },
    chckedItems: [
      { title: 'Management of 8+ projects', sx: { fontFamily: 'Gellix-Bold' } },
      { title: 'Built-in chat & video calls' },
      { title: 'Company calendar' },
      { title: 'Notifications' },
      { title: 'Ready-to-use contracts' },
      { title: 'Milestone & task management' },
      { title: 'Payment processing' },
      { title: 'Expense tracking' },
      { title: 'Priority support' },
      { title: 'Dedicated account manager' },
    ],
  },
  selfService: {
    name: 'Self-service',
    chckedItems: [
      { title: 'Unlimited pitches' },
      { title: 'Seller evaluation tools' },
      { title: 'Built-in selection tool' },
      { title: 'Built-in chat & video calls' },
      { title: 'Company calendar' },
      { title: 'Ready-to-use contracts' },
      { title: 'Milestone & task management' },
      { title: 'Invoice management' },
      { title: 'Payment processing' },
      { title: 'Expense tracking' },
    ],
    footerText: `3% fee per project to cover payment processing and Spona services when successfully matched
        with a digital service seller`,
  },
  sponaConnect: {
    name: 'Spona Connect',
    subtitle: 'Everything in self-service, plus:',
    chckedItems: [
      { title: 'Market analysis' },
      { title: 'Competitor benchmarking' },
      { title: 'Project creation' },
      { title: 'Tailored seller outreach' },
      { title: 'Pitch analysis' },
      { title: 'Project creation' },
      { title: 'Budget negotiation' },
      { title: 'Priority support' },
      { title: 'Dedicated success manager' },
    ],
    footerText: `5% fee per project to cover payment processing and Spona services when successfully matched
        with a digital service seller`,
  },
};

const BuyerPricingContent = ({ type }: { type: BuyerPricingType }) => {
  const [activeBtn, setActiveBtn] = useState<BuyerPricingType>(type);
  const handleTabVariants = (btnName: string) =>
    activeBtn === btnName ? 'primary' : 'primaryLight';

  const navigate = useNavigate();

  const {
    authData: { userData },
  } = useAuth();

  const userCompany = useUserCompany();
  const isBuyer = userCompany?.roles?.includes(CompanyRole.Buyer);
  useEffect(() => {
    if (userCompany && !isBuyer) {
      navigate('/how-to-manage-seller/pricing');
    }
  }, [userCompany, isBuyer, navigate]);

  const { data: subscriptionInfoResponse, isLoading: isGetDataLoading } =
    useGetOne<CompanySubscriptionInfo>({
      resource: `companies/${userData?.membership?.company}/active-subscription`,
      enabled: !!userData?.membership?.company,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
    });

  const { data: pricesResponse, isLoading: isGetPricesLoading } = useGetMany<SubscriptionPriceDto>({
    resource: `subscription-prices`,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const subscriptionInfo = subscriptionInfoResponse?.data;
  const pricies = pricesResponse?.data.results;
  const basicMonthlyPriceId = pricies?.find(
    (price) => price.lookupKey === EnumPriceLookup.BasicMonthly,
  )?.sId;
  const standardMonthlyPriceId = pricies?.find(
    (price) => price.lookupKey === EnumPriceLookup.StandardMonthly,
  )?.sId;
  const premiumMonthlyPriceId = pricies?.find(
    (price) => price.lookupKey === EnumPriceLookup.PremiumMonthly,
  )?.sId;

  const { mutate: muatateCheckout, isLoading } = useCreate<
    SubscriptionSubscribeDto,
    CreateSubscriptionSubscribeDto
  >({
    resource: '/subscriptions/subscribe',
  });

  const handleCheckout = (priceId: string | null | undefined) => {
    if (!priceId) return;
    muatateCheckout(
      { price_id: priceId },
      {
        onSuccess: (data) => {
          window.location.href = data.data.url;
        },
      },
    );
  };

  const defaultProps: CheckoutButtonDefaultProps = {
    buttonProps: {
      onClick: () => {
        navigate('/register');
      },
      text: 'try for free',
      disabled: false,
      variant: 'primary' as Variants,
      loading: isLoading,
    },
    freeTrialText: `Try for free for 30 days`,
  };

  const basicProps = {
    ...defaultProps,
    buttonProps: {
      ...defaultProps.buttonProps,
    },
  };
  const standardProps = {
    ...defaultProps,
    buttonProps: {
      ...defaultProps.buttonProps,
      sx: { mt: 5 },
    },
  };
  const premiumProps = {
    ...defaultProps,
    buttonProps: {
      ...defaultProps.buttonProps,
    },
  };

  const selfServiceButtonProps = {
    onClick: () => {
      navigate('/register');
    },
  };

  const sponaConnectButtonProps = {
    onClick: () => {
      navigate('/register');
    },

    sx: { mt: 5 },
  };

  if (userData) {
    if (subscriptionInfo) {
      basicProps.buttonProps.onClick = () => handleCheckout(basicMonthlyPriceId);
      standardProps.buttonProps.onClick = () => handleCheckout(standardMonthlyPriceId);
      premiumProps.buttonProps.onClick = () => handleCheckout(premiumMonthlyPriceId);

      if (subscriptionInfo.activeSubscription || subscriptionInfo.isFreeTrialUsed) {
        basicProps.freeTrialText = '';
        standardProps.freeTrialText = '';
        premiumProps.freeTrialText = '';
      }

      if (!subscriptionInfo.activeSubscription && !subscriptionInfo.isFreeTrialUsed) {
        basicProps.buttonProps.text = 'Try for free';
        standardProps.buttonProps.text = 'Try for free';
        premiumProps.buttonProps.text = 'Try for free';
      } else if (!subscriptionInfo.activeSubscription && subscriptionInfo.isFreeTrialUsed) {
        basicProps.buttonProps.text = 'Subscribe';
        standardProps.buttonProps.text = 'Subscribe';
        premiumProps.buttonProps.text = 'Subscribe';
      } else if (subscriptionInfo.activeSubscription?.price.product.name === 'Basic') {
        basicProps.buttonProps.text = 'Selected';
        basicProps.buttonProps.disabled = true;
        basicProps.buttonProps.variant = 'secondary' as Variants;
        standardProps.buttonProps.text = 'Manage Subscription';
        premiumProps.buttonProps.text = 'Manage Subscription';
      } else if (subscriptionInfo.activeSubscription?.price.product.name === 'Standard') {
        basicProps.buttonProps.text = 'Manage Subscription';
        standardProps.buttonProps.text = 'Selected';
        standardProps.buttonProps.disabled = true;
        standardProps.buttonProps.variant = 'secondaryDark' as Variants;
        premiumProps.buttonProps.text = 'Manage Subscription';
      } else if (subscriptionInfo.activeSubscription?.price.product.name === 'Premium') {
        basicProps.buttonProps.text = 'Manage Subscription';
        standardProps.buttonProps.text = 'Manage Subscription';
        premiumProps.buttonProps.text = 'Manage Subscription';
        premiumProps.buttonProps.text = 'Selected';
        premiumProps.buttonProps.disabled = true;
        premiumProps.buttonProps.variant = 'secondaryDark' as Variants;
      }
    } else if (userData.membership === null) {
      basicProps.buttonProps.onClick = () => navigate('/dashboard');
      standardProps.buttonProps.onClick = () => navigate('/dashboard');
      premiumProps.buttonProps.onClick = () => navigate('/dashboard');
    }
    selfServiceButtonProps.onClick = () => {
      navigate('/dashboard/timed-projects/create');
    };
    sponaConnectButtonProps.onClick = () => {
      window.location.href = process.env.REACT_APP_CALENDLY_URL as string;
    };
  }

  return (
    <>
      <Stack direction="row">
        <CustomButton
          sx={{ width: { lg: '15rem' }, borderRadius: '20px 0px 0px 20px' }}
          variant={handleTabVariants('hire')}
          onClick={() => setActiveBtn('hire')}
        >
          Hire and manage
        </CustomButton>
        <CustomButton
          sx={{ width: { lg: '15rem' }, borderRadius: '0px 20px 20px 0px' }}
          variant={handleTabVariants('manage')}
          onClick={() => setActiveBtn('manage')}
        >
          Manage
        </CustomButton>
      </Stack>
      <Stack sx={{ ...LAYOUT_DEFAULTS }} alignItems="center">
        {activeBtn === 'manage' ? (
          <Stack
            direction={{ lg: 'row' }}
            alignItems="center"
            width="100%"
            minHeight="100%"
            maxWidth="1310px"
            my={8}
            gap={{ xs: 5, lg: 0 }}
          >
            <PricingCard
              purple
              sx={{
                mr: { lg: '-32px' },
                width: { xs: '100%', lg: '50%' },
                maxWidth: '500px',
              }}
            >
              <PlanDetails data={buyerPricingData.basic} {...basicProps} />
            </PricingCard>

            <PricingCard
              sx={{
                width: { xs: '100%', lg: '50%' },
                maxWidth: '500px',
                zIndex: 4,
              }}
            >
              <PlanDetails
                data={buyerPricingData.standard}
                {...standardProps}
                mainStackProps={{ sx: { py: { xs: 0, lg: '15px' } } }}
              />
            </PricingCard>

            <PricingCard
              purple
              sx={{
                ml: { lg: '-32px' },
                width: { xs: '100%', lg: '50%' },
                maxWidth: '500px',
              }}
            >
              <PlanDetails data={buyerPricingData.premium} {...premiumProps} />
            </PricingCard>
          </Stack>
        ) : (
          <Stack
            direction={{ md: 'row' }}
            alignItems={{ xs: 'center', md: 'stretch' }}
            width={{ lg: '100%' }}
            minHeight="100%"
            maxWidth="932px"
            my={8}
            gap={{ xs: 5, md: 0 }}
            sx={{ mr: { md: '-32px' } }}
          >
            <PricingCard
              sx={{ mr: { md: '-32px' }, width: { xs: '100%', md: '50%' }, maxWidth: '500px' }}
            >
              <PlanDetails
                data={buyerPricingData.selfService}
                freeTrial={false}
                mainStackProps={{ justifyContent: 'space-between', gap: 3.5 }}
                itemsStackProps={{ sx: { width: '80%', mt: 2 } }}
                buttonProps={selfServiceButtonProps}
              />
            </PricingCard>
            <PricingCard purple sx={{ width: { xs: '100%', md: '50%' }, maxWidth: '500px' }}>
              <PlanDetails
                data={buyerPricingData.sponaConnect}
                freeTrial={false}
                mainStackProps={{ justifyContent: 'space-between', gap: 3 }}
                itemsStackProps={{ sx: { width: '80%' } }}
                // buttonProps={{ sx: { mt: 5 } }}
                buttonProps={sponaConnectButtonProps}
              />
            </PricingCard>
          </Stack>
        )}
        <LogoSection />
        <PricingFaq />
      </Stack>
      <GetDemoSection type={activeBtn} />
    </>
  );
};

export default BuyerPricingContent;
