import { Stack } from '@mui/material';
import { CompanySubscriptionInfo } from 'tdc-web-backend/companies/schemas';
import { SubscriptionPriceDto } from 'tdc-web-backend/subscription-prices/schemas';
import {
  CreateSubscriptionSubscribeDto,
  SubscriptionSubscribeDto,
} from 'tdc-web-backend/subscriptions/schemas';
import { CompanyRole, EnumPriceLookup, UserRoles } from 'tdc-web-backend/enums/enums';
import { useNavigate } from 'react-router-dom';
import { LAYOUT_DEFAULTS } from '../../../../../utils/constants';
import { Variants } from '../../../../../components/button/CustomButton';
import LogoSection from '../../components/LogoSection';
import PricingFaq from '../../components/PricingFaq';
import GetDemoSection from '../../components/GetDemoSection/GetDemoSection';
import PricingCard from '../../components/PricingCard';
import PlanDetails from '../../components/PlanDetails/PlanDetails';
import { CheckoutButtonDefaultProps, IPlanDetails } from '../../interface';
import useAuth from '../../../../../utils/hooks/useAuth';
import useGetOne from '../../../../../utils/hooks/crud-hooks/useGetOne';
import useCreate from '../../../../../utils/hooks/crud-hooks/useCreate';
import useGetMany from '../../../../../utils/hooks/crud-hooks/useGetMany';
import useUserCompany from '../../../../../utils/hooks/useUserCompany';
import { useEffect } from 'react';

type ISellerPricingData = {
  basic: IPlanDetails;
  standard: IPlanDetails;
  premium: IPlanDetails;
};

export const sellerPricingData: ISellerPricingData = {
  basic: {
    name: 'Basic',
    pricing: {
      monthlyPrice: '€99.99',
      annualPrice: '€84.99',
    },
    chckedItems: [
      { title: 'Management of up to 2 projects', sx: { fontFamily: 'Gellix-Bold' } },
      { title: 'Built-in chat & video calls' },
      { title: 'Company calendar' },
      { title: 'Notifications' },
      { title: 'Ready-to-use contracts' },
      { title: 'Milestone & task management' },
      { title: 'Payment processing' },
      { title: 'Expense tracking' },
      { title: '', hideCheck: true },
      { title: '', hideCheck: true },
    ],
  },
  standard: {
    name: 'Standard',
    pricing: {
      monthlyPrice: '€299.99',
      annualPrice: '€254.99',
    },
    chckedItems: [
      { title: 'Management of up to 8 projects', sx: { fontFamily: 'Gellix-Bold' } },
      { title: 'Built-in chat & video calls' },
      { title: 'Company calendar' },
      { title: 'Notifications' },
      { title: 'Ready-to-use contracts' },
      { title: 'Milestone & task management' },
      { title: 'Payment processing' },
      { title: 'Expense tracking' },
      { title: 'Priority support' },
      { title: 'Dedicated account manager' },
    ],
  },
  premium: {
    name: 'Premium',
    pricing: {
      monthlyPrice: '€799.99',
      annualPrice: '€679.99',
    },
    chckedItems: [
      { title: 'Management of 8+ projects', sx: { fontFamily: 'Gellix-Bold' } },
      { title: 'Built-in chat & video calls' },
      { title: 'Company calendar' },
      { title: 'Notifications' },
      { title: 'Ready-to-use contracts' },
      { title: 'Milestone & task management' },
      { title: 'Payment processing' },
      { title: 'Expense tracking' },
      { title: 'Priority support' },
      { title: 'Dedicated account manager' },
    ],
  },
};

const SellerPricingContent = () => {
  const navigate = useNavigate();

  const {
    authData: { userData },
  } = useAuth();
  const userCompany = useUserCompany();
  const isBuyer = userCompany?.roles?.includes(CompanyRole.Buyer);

  useEffect(() => {
    if (userCompany && isBuyer) {
      navigate('/how-to-manage-buyer/pricing');
    }
  }, [userCompany, isBuyer, navigate]);

  const { data: subscriptionInfoResponse, isLoading: isGetDataLoading } =
    useGetOne<CompanySubscriptionInfo>({
      resource: `companies/${userData?.membership?.company}/active-subscription`,
      enabled: !!userData?.membership?.company,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
    });

  const { data: pricesResponse, isLoading: isGetPricesLoading } = useGetMany<SubscriptionPriceDto>({
    resource: `subscription-prices`,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const subscriptionInfo = subscriptionInfoResponse?.data;
  const pricies = pricesResponse?.data.results;
  const basicMonthlyPriceId = pricies?.find(
    (price) => price.lookupKey === EnumPriceLookup.BasicMonthly,
  )?.sId;
  const standardMonthlyPriceId = pricies?.find(
    (price) => price.lookupKey === EnumPriceLookup.StandardMonthly,
  )?.sId;
  const premiumMonthlyPriceId = pricies?.find(
    (price) => price.lookupKey === EnumPriceLookup.PremiumMonthly,
  )?.sId;

  const { mutate: muatateCheckout, isLoading } = useCreate<
    SubscriptionSubscribeDto,
    CreateSubscriptionSubscribeDto
  >({
    resource: '/subscriptions/subscribe',
  });

  const handleCheckout = (priceId: string | null | undefined) => {
    if (!priceId) return;
    muatateCheckout(
      { price_id: priceId },
      {
        onSuccess: (data) => {
          window.location.href = data.data.url;
        },
      },
    );
  };

  const defaultProps: CheckoutButtonDefaultProps = {
    buttonProps: {
      onClick: () => {
        navigate('/register');
      },
      text: 'try for free',
      disabled: false,
      variant: 'primary' as Variants,
      loading: isLoading,
    },
    freeTrialText: `Try for free for 30 days`,
  };

  const basicProps = {
    ...defaultProps,
    buttonProps: {
      ...defaultProps.buttonProps,
    },
  };
  const standardProps = {
    ...defaultProps,
    buttonProps: {
      ...defaultProps.buttonProps,
    },
  };
  const premiumProps = {
    ...defaultProps,
    buttonProps: {
      ...defaultProps.buttonProps,
    },
  };

  if (userData) {
    if (subscriptionInfo) {
      basicProps.buttonProps.onClick = () => handleCheckout(basicMonthlyPriceId);
      standardProps.buttonProps.onClick = () => handleCheckout(standardMonthlyPriceId);
      premiumProps.buttonProps.onClick = () => handleCheckout(premiumMonthlyPriceId);

      if (subscriptionInfo.activeSubscription || subscriptionInfo.isFreeTrialUsed) {
        basicProps.freeTrialText = '';
        standardProps.freeTrialText = '';
        premiumProps.freeTrialText = '';
      }

      if (!subscriptionInfo.activeSubscription && !subscriptionInfo.isFreeTrialUsed) {
        basicProps.buttonProps.text = 'Try for free';
        standardProps.buttonProps.text = 'Try for free';
        premiumProps.buttonProps.text = 'Try for free';
      } else if (!subscriptionInfo.activeSubscription && subscriptionInfo.isFreeTrialUsed) {
        basicProps.buttonProps.text = 'Subscribe';
        standardProps.buttonProps.text = 'Subscribe';
        premiumProps.buttonProps.text = 'Subscribe';
      } else if (subscriptionInfo.activeSubscription?.price.product.name === 'Basic') {
        basicProps.buttonProps.text = 'Selected';
        basicProps.buttonProps.disabled = true;
        basicProps.buttonProps.variant = 'secondary' as Variants;
        standardProps.buttonProps.text = 'Manage Subscription';
        premiumProps.buttonProps.text = 'Manage Subscription';
      } else if (subscriptionInfo.activeSubscription?.price.product.name === 'Standard') {
        basicProps.buttonProps.text = 'Manage Subscription';
        standardProps.buttonProps.text = 'Selected';
        standardProps.buttonProps.disabled = true;
        standardProps.buttonProps.variant = 'secondaryDark' as Variants;
        premiumProps.buttonProps.text = 'Manage Subscription';
      } else if (subscriptionInfo.activeSubscription?.price.product.name === 'Premium') {
        basicProps.buttonProps.text = 'Manage Subscription';
        standardProps.buttonProps.text = 'Manage Subscription';
        premiumProps.buttonProps.text = 'Manage Subscription';
        premiumProps.buttonProps.text = 'Selected';
        premiumProps.buttonProps.disabled = true;
        premiumProps.buttonProps.variant = 'secondaryDark' as Variants;
      }
    } else if (userData.membership === null) {
      basicProps.buttonProps.onClick = () => navigate('/dashboard');
      standardProps.buttonProps.onClick = () => navigate('/dashboard');
      premiumProps.buttonProps.onClick = () => navigate('/dashboard');
    }
  }

  return (
    <>
      <Stack sx={{ ...LAYOUT_DEFAULTS }} alignItems="center">
        <Stack
          direction={{ lg: 'row' }}
          alignItems="center"
          width="100%"
          minHeight="100%"
          maxWidth="1310px"
          my={8}
          gap={{ xs: 5, lg: 0 }}
        >
          <PricingCard
            purple
            sx={{
              mr: { lg: '-32px' },
              width: { xs: '100%', lg: '50%' },
              maxWidth: '500px',
            }}
          >
            <PlanDetails data={sellerPricingData.basic} {...basicProps} />
          </PricingCard>

          <PricingCard
            sx={{
              width: { xs: '100%', lg: '50%' },
              maxWidth: '500px',
              zIndex: 4,
            }}
          >
            <PlanDetails
              data={sellerPricingData.standard}
              {...standardProps}
              mainStackProps={{ sx: { py: { xs: 0, lg: '15px' } } }}
            />
          </PricingCard>

          <PricingCard
            purple
            sx={{
              ml: { lg: '-32px' },
              width: { xs: '100%', lg: '50%' },
              maxWidth: '500px',
            }}
          >
            <PlanDetails data={sellerPricingData.premium} {...premiumProps} />
          </PricingCard>
        </Stack>
        <LogoSection />
        <PricingFaq />
      </Stack>
      <GetDemoSection type="manage" />
    </>
  );
};
export default SellerPricingContent;
